import React,{FC} from "react";
// import { Outlet } from "react-router-dom";
import MainLayout from "./MainLayout";
const QuestionLayout:FC =() =>{
    return <>
        <MainLayout></MainLayout>
        
    </>
}

export default QuestionLayout;